/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 665px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 66.86746987951807%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBBAX/xAAWAQEBAQAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAcl9ZukqIE//xAAZEAADAAMAAAAAAAAAAAAAAAABAgMAEBH/2gAIAQEAAQUCUdNIlF01Cwz/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAWEQADAAAAAAAAAAAAAAAAAAAQESH/2gAIAQIBAT8BrH//xAAWEAEBAQAAAAAAAAAAAAAAAAARACD/2gAIAQEABj8CEnP/xAAaEAEBAAMBAQAAAAAAAAAAAAABEQAQITFB/9oACAEBAAE/IYaFfXAjk55oY3J54a//2gAMAwEAAgADAAAAEHD/AP/EABYRAQEBAAAAAAAAAAAAAAAAABEBEP/aAAgBAwEBPxBhn//EABcRAQADAAAAAAAAAAAAAAAAAAEQETH/2gAIAQIBAT8QShMj/8QAGhABAQADAQEAAAAAAAAAAAAAAREAECExcf/aAAgBAQABPxAxh+AzyQA1eumMSje5FVGpfXX/2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"maine flag\"\n        title=\"maine flag\"\n        src=\"/static/60a8f2d9881a9e48378017140684b185/0d8e0/maine-flag.jpg\"\n        srcset=\"/static/60a8f2d9881a9e48378017140684b185/4d5fb/maine-flag.jpg 166w,\n/static/60a8f2d9881a9e48378017140684b185/558f0/maine-flag.jpg 333w,\n/static/60a8f2d9881a9e48378017140684b185/0d8e0/maine-flag.jpg 665w,\n/static/60a8f2d9881a9e48378017140684b185/90334/maine-flag.jpg 998w,\n/static/60a8f2d9881a9e48378017140684b185/6c738/maine-flag.jpg 1200w\"\n        sizes=\"(max-width: 665px) 100vw, 665px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Maine has the distinction of being the only state in the U.S. with a one syllable name. "), "\n", React.createElement(_components.p, null, "The origins of the name are in question as there is no clear explanation. "), "\n", React.createElement(_components.p, null, "Several theories suggest that the namer’s of the area had spent much time in the Orkney Islands where the primary island is called the mainland – Maine could be derived from that reference."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
